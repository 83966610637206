/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Open+Sans:wght@300;400;500;700&display=swap&family=Cairo:ital,wght@0,400'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Open+Sans:wght@300;400;500;700&family=Cairo:ital,wght@0,400&family=Noto+Sans:wght@400;700&family=Inter:wght@400;700&family=Roboto:wght@400;700&display=swap');

  @font-face {
    font-family: 'Selawik';
    src: url('../../fonts/Selawik-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Selawik';
    src: url('../../fonts/Selawik-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Selawik';
    src: url('../../fonts/Selawik-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Selawik';
    src: url('../../fonts/Selawik-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  
  :root {
  --primary: #0967d2;
  --secondary: #002159;
  --light-blue: #bae3ff;

  --danger: #eb3232;
  --warning: #fbc93d;
  --orange: #ff8b20;

  --dark-red: #BD3737;

  --main-green: #83BD37;
  --dark-green: #359e65;
  --light-green: #90e0b4;

  --white: #fff;
  --bg-blue: #f5f7fa;
  --border-blue: #dfe5f7;

  --editor-disabled: #f0f0f0;
  --disabled: #d8d8d8;
  --ghost: #bdc7de;
  --dark: #55606c;
  --navy-grey: #7d8793;
  --dark-gray: #979797;
  --bold: 'OpenSans-Bold';
  --regular: 'OpenSans-Regular';
  --px-50: 50px;
  --px-30: 30px;
  --px-25: 25px;
  --px-20: 20px;
  --px-18: 18px;
  --px-16: 16px;
  --px-14: 14px;
  --px-12: 12px;
  --px-10: 10px;
  --shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
}

/* FONT WEIGHTS */
.font-bold {
  font-family: var(--bold) !important;
}
.font-regular {
  font-family: var(--regular) !important;
}
/* FONT SIZES */
.font-50 {
  font-size: var(--px-50) !important;
}
.font-30 {
  font-size: var(--px-30) !important;
}
.font-25 {
  font-size: var(--px-25) !important;
}
.font-20 {
  font-size: var(--px-20) !important;
}
.font-18 {
  font-size: var(--px-18) !important;
}
.font-16 {
  font-size: var(--px-16) !important;
}
.font-14 {
  font-size: var(--px-14) !important;
}
.font-12 {
  font-size: var(--px-12) !important;
}
.font-10 {
  font-size: var(--px-10) !important;
}

/* COLORS */
.primary-b {
  color: var(--primary) !important;
}
.secondary-b {
  color: var(--secondary) !important;
}
.danger-red {
  color: var(--danger) !important;
}
.disabled-grey {
  color: var(--dark-gray) !important;
}
.disabled-g {
  color: var(--disabled) !important;
}
.white {
  color: var(--white) !important;
}
